import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

import * as S from "../styles";

const LineTableExp = ({ entity }) => {
    const [modalLineTable, setModalLineTable] = useState(false);

    const handleModalLineTable = () => {
        setModalLineTable(!modalLineTable);
    };

    const handleColor = status => {
        if (status === "A") return "#06F90E";
        else if (status === "B") return "#F8991C";
        else if (status === "C") return "#FB1B41";
        else return "";
    };

    return (
        <S.LineBodyTable key={entity?.id} className={modalLineTable ? "" : "border-b-2"}>
            <S.Columns onClick={handleModalLineTable}>
                <S.ColumnBodyTable width="130">
                    <S.BoxIcon>
                        {!modalLineTable ? <IoIosArrowForward color="#252525" /> : <IoIosArrowDown color="#252525" />}
                    </S.BoxIcon>
                    <S.LineBodyText>{entity?.statusNf}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="190">
                    <S.LineBodyText>{entity?.usuarioConferencia}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="130">
                    <S.LineBodyText>{entity?.stateCodeFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="140" align="right" paddingRight="40">
                    {entity?.status && <S.Circle color={handleColor(entity?.status)} />}
                    <S.LineBodyText>{entity?.nuNota}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable onClick={handleModalLineTable} width="100" align="right" paddingRight="40">
                    <S.LineBodyText>{entity?.serieNf}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="140" align="right" paddingRight="80">
                    {entity?.status && <S.Circle color={handleColor(entity?.status)} />}
                    <S.LineBodyText>{entity?.idPedido}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="175">
                    <S.LineBodyText>{entity?.dtFaturamentoFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="400" paddingRight="20">
                    <S.LineBodyText>{entity?.nomeDepositante}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="160">
                    <S.LineBodyText>{entity?.ufDestinatario}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable onClick={handleModalLineTable} width="400" paddingLeft="15">
                    <S.LineBodyText>{entity?.recipientCity}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="400">
                    <S.LineBodyText>{entity?.nomeEntrega}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="500">
                    <S.LineBodyText>{entity?.nomeTransportadora}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="140">
                    <S.LineBodyText>{entity?.embLiberado}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="160">
                    <S.LineBodyText>{entity?.dtFimColetaFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="120" align="right" paddingRight="40">
                    <S.LineBodyText>{entity?.qtVolumesFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="160" align="right" paddingRight="40">
                    <S.LineBodyText>{entity?.valorNfFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="120" align="right" paddingRight="40">
                    <S.LineBodyText>{entity?.cubagemM3Formatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="120" align="right">
                    <S.LineBodyText>{entity?.weightFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
            </S.Columns>
            {modalLineTable && (
                <S.CardInfoLine>
                    <S.CardTable>
                        <S.CardTableLine>
                            <S.CardTableColumn width="240">
                                <S.LineHeadText>Código</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn width="700">
                                <S.LineHeadText>Descrição</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn width="130">
                                <S.LineHeadText>Quantidade</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn>
                                <S.LineHeadText>Lote Indústria</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn width="350">
                                <S.LineHeadText>Número de série</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn align="center">
                                <S.LineHeadText>Curva ABC</S.LineHeadText>
                            </S.CardTableColumn>
                        </S.CardTableLine>
                        {entity?.itens && entity?.itens?.length > 0 ? (
                            entity?.itens?.map((entity2, key) => {
                                return (
                                    <S.CardTableLineBody>
                                        <S.CardTableColumnBody width="240">
                                            <S.LineBodyText>{entity2?.codProduto}</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody width="700">
                                            <S.LineBodyText>{entity2?.descricaoProduto}</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody width="130" align="right" paddingRight="40">
                                            <S.LineBodyText>{entity2?.qtdeExpedidaFormatted}</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody>
                                            <S.LineBodyText>{entity2?.loteIndustrial}</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody width="350" paddingRight="40">
                                            <S.LineBodyText>{entity2?.infoEspecifica}</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody align="center">
                                            <S.LineBodyText color={handleColor(entity2?.inAbc)}>
                                                {entity2?.inAbc}
                                            </S.LineBodyText>
                                        </S.CardTableColumnBody>
                                    </S.CardTableLineBody>
                                );
                            })
                        ) : (
                            <S.LineBodyTextCenter>Nenhum dado encontrado</S.LineBodyTextCenter>
                        )}
                    </S.CardTable>
                </S.CardInfoLine>
            )}
        </S.LineBodyTable>
    );
};

export default LineTableExp;
