import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import { ClipLoader } from "react-spinners";

import requests from "../../../services/requests";
import TitleC6 from "../../../components/Typography/TitleC6";
import Card from "../../../components/Cards";
import { customLabels, customLegend, customMarkers } from "../shared/layout";
import { createSeries } from "../shared/createElements";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
am4core.useTheme(am4themesAnimated);

export default function DeliveryStatusGraph({
    modality,
    title,
    searchPeriod,
    selectedClient,
    selectedUf,
    statusGrafico,
    statusPedido,
    handleStatus,
    setMeta,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(true);
    const filtered = useRef(null);

    const x = useRef(null);

    const getData = useCallback(async chart => {
        if (modality === "STATUS_COLETA") {
            if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
                requests
                    .dashboardPickingPack({
                        ...searchPeriod,
                        idArmazem: selectedUf?.value,
                        clientsWarehouse: selectedClient,
                        statusPedido,
                        statusGrafico,
                        type_storage: "expedicao",
                    }, "expedition")
                    .then(data => {
                        let aux = {
                            category: "",
                            agendada: data.data.collected,
                            iniciada: data.data.collectingStarted,
                            finalizada: data.data.coletaFinalizada,
                        };

                        chart.data = [aux];

                        setIsLoading(false);
                    });
            }
        }

        chart.data = [];
    });

    useLayoutEffect(() => {
        const chart = am4core.create("DeliveryStatusGraph", am4charts.XYChart);
        chart.language.locale = am4lang_pt_BR;

        chart.colors.list = [am4core.color("#70D499"), am4core.color("#F3D783"), am4core.color("#EC5D66")];

        chart.events.on("beforedatavalidated", function (ev) {
            var data = chart.data;
            if (data.length === 0) {
                return;
            }

            var total = data[0].agendada + data[0].iniciada + data[0].finalizada;
            var values = [
                data[0].agendada / total,
                data[0].agendada / total + data[0].iniciada / total,
                data[0].agendada / total + data[0].iniciada / total + data[0].finalizada / total,
            ];

            for (var i = 0; i < values.length; i++) {
                var range = xAxis.axisRanges.create();
                range.value = values[i] * 100;
                range.label.text = Math.round(values[i] * 10000) / 100 + "%";
                // range.label.horizontalCenter = "top";
                range.label.marginTop = -50;
                range.label.paddingLeft = 5;
                range.label.paddingTop = 5;
                range.label.fontSize = 10;
                range.label.inside = true;
                // range.label.rotation = 90;
                range.grid.strokeOpacity = 0.2;
                range.tick.length = 18;
                range.tick.strokeOpacity = 0.2;
            }
        });

        // Create axes
        const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "category";
        yAxis.renderer.opposite = true;

        const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.min = 0;
        xAxis.max = 100;
        xAxis.strictMinMax = true;
        xAxis.calculateTotals = true;
        xAxis.renderer.minHeight = 40;
        xAxis.renderer.disabled = true;
        xAxis.renderer.grid.template.disabled = true;

        const xLabels = xAxis.renderer.labels;
        customLabels(xLabels);

        let seriesC = createSeries(chart, "finalizada", "Coleta finalizada", 7, function (ev) {
            if (handleStatus) {
                setMeta({
                    current_page: 1,
                });

                var removeFilter = false;

                if (filtered.current === "finalizada") {
                    filtered.current = null;
                    handleStatus(null);

                    removeFilter = true;
                } else {
                    filtered.current = "finalizada";
                    handleStatus("coletaFinalizada");

                    removeFilter = false;
                }

                chart.series.each(function (series, k) {
                    if (series instanceof am4charts.ColumnSeries) {
                        series.columns.each(function (column) {
                            if (filtered.current === null) {
                                column.isActive = false; // Se não tiver filtro, desativa o filtro em todos
                            } else if (k !== 2 && filtered.current === "finalizada") {
                                column.isActive = true; // Se o escolhido for o C, ativa o filtro nos outros que não são o C
                            } else {
                                column.isActive = false;
                            }
                        });
                    }
                });
            }
        });

        var tActiveStateC = seriesC.columns.template.states.create("active");
        tActiveStateC.properties.fill = "#CECECE";

        const legend = customLegend(chart);
        customMarkers(legend);

        legend.maxHeight = undefined;
        legend.itemContainers.template.togglable = false;

        getData(chart);

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [searchPeriod, selectedClient, selectedUf]);

    return (
        <>
            {(isLoading || total > 0) && (
                <Card>
                    <TitleC6>{title}</TitleC6>
                    <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                        {isLoading && <ClipLoader size={20} loading={isLoading} />}

                        <div id="DeliveryStatusGraph" style={{ width: "100%", height: "120px" }} />
                    </div>
                </Card>
            )}
        </>
    );
}
