import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsArrowUpDown } from "react-icons/bs";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";

import * as S from "../styled/styles";

import HeaderArmazem from "../../../components/HeaderArmazem";
import Layout from "../../../components/Layout";
import LineTableExp from "../../../components/Lines/LineTableExp";
import Paginate from "../../../components/Paginate/Paginate";
import requests from "../../../services/requests";
import ModalExport from "../components/ModalExport";
import TitleC6 from "../../../components/Typography/TitleC6";
import Card from "../../../components/Cards";
import ExportarIcon from "../../../assets/svgs/exportar.inline.svg";

import ABCAnalysisGraph from "../../../components/Graphs/ABCAnalysisGraph";
import GraphWarehouseSla from "../../../components/Graphs/GraphWarehouseSla";
import DeliveryStatusGraph from "../../../components/Graphs/DeliveryStatusGraph";
import Modal from "../../../components/Modals";
import XpandContainer from "../../../components/XpandContainer";

const IndexPage = () => {
    const [load, setLoad] = useState(false);
    const [list, setList] = useState([]);
    const [action, setAction] = useState(1);
    const [selectedUf, setSelectedUf] = useState(
        JSON.parse(window.localStorage.getItem("warehouse-filter-state"))?.value !== null
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-state"))
            : { value: null, label: "TODOS" }
    );
    const searchPeriod = useSelector(state => state.period);
    const [selectedClient, setSelectedClient] = useState(
        window.localStorage.getItem("warehouse-filter-client")
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-client"))
            : []
    );
    const [searchNfe, setSearchNfe] = useState(null);
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [orderList, setOrderList] = useState(null);
    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [clientsOptions, setClientsOptions] = useState([]);
    const [modalExport, setModalExport] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [statusPedido, setStatusPedido] = useState(null);
    const [statusGrafico, setStatusGrafico] = useState(null);
    const [dataExpedition, setDataExpedition] = useState({});
    const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
    const [openModalGraphExpedition, setOpenModalGraphExpedition] = useState(false);
    const [tabModal, setTabModal] = useState("Análise de remessas por dia");

    //Função que gera arquivo para exportar lista
    const downloadList = () => {
        setIsDownloading(true);
        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            type_storage: "expedicao",
            nfeNumber: searchNfe,
            statusPedido,
            statusGrafico,
            order: orderList,
        };

        var date = new Date();

        requests
            .exportListPickingExpedicao(filters)
            .then(response => {
                const fileType = response.headers["content-type"];
                const blob = new Blob([response.data], { fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", 'Expedicao' + '-' + date.getFullYear() + date.getMonth() + date.getDate() + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => {
                setIsDownloading(false);
                setModalExport(false);
            });
    };

    //Função que gera os gráficos
    const showMetrics = () => {
        setDataExpedition({});
        setIsLoadingMetrics(true);

        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            statusPedido,
            statusGrafico,
            type_storage: "expedicao",
        };

        requests.remittanceAnalysisExpedition(filters).then(data => {
            if (data.data.average === 0) {
                data.data.average = '0';
            }

            setDataExpedition(data.data);
        });

        setIsLoadingMetrics(false);
    };

    const actionModalGraphExpedition = () => {
        setOpenModalGraphExpedition(!openModalGraphExpedition);
        setTabModal("Análise de remessas por dia");
    };

    const closeModalGraphExpedition = () => {
        setOpenModalGraphExpedition(false);
    };

    //Função que gera a listagem
    const listPickingPack = () => {
        setLoad(true);

        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            type_storage: "expedicao",
            nfeNumber: searchNfe,
            statusPedido,
            statusGrafico,
            order: orderList,
        };

        requests.listPickingPack(filters, meta.current_page > 0 ? meta.current_page : 1).then(data => {
            setList(data.data);
            setMeta(data.meta);
            setLoad(false);
        });
    };

    //Função que gera a lista de clientes do filtro
    const loadClientOptions = (search = "") => {
        setNoClientOptionsMessage("Carregando");
        setIsLoadingClients(true);

        if (selectedClient?.length > 0 && search.length < 2) {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        aux.push({
                            value: client.identifier,
                            label: client.name + " (" + client.documentNumberFormatted + ")",
                        });
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        } else if (search.length < 2) {
            setNoClientOptionsMessage("Insira ao menos 2 caracteres para fazer a busca");
            setIsLoadingClients(false);
        } else {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        }
    };

    const actionModalExport = () => {
        setModalExport(!modalExport);
    };

    const validateOrder = (object, column) => {
        if (object?.order_campo === column) {
            return true;
        } else {
            return false;
        }
    };

    const handleOrder = (campo, order = 0) => {
        if (order > 0) {
            if (order === 3) {
                if (orderList?.order_campo === campo && orderList?.order === "DESC") {
                    setOrderList({
                        order_campo: campo,
                        order: "ASC",
                    });
                } else if (orderList?.order_campo === campo && orderList?.order === "ASC") {
                    setOrderList(null);
                } else {
                    setOrderList({
                        order_campo: campo,
                        order: "DESC",
                    });
                }
            } else {
                setOrderList({
                    order_campo: campo,
                    order: order === 1 ? "DESC" : "ASC",
                });
            }
        } else {
            setOrderList(null);
        }
    };

    const handleSearchNfe = number => {
        setSearchNfe(number);
    };

    useEffect(() => {
        loadClientOptions();
    }, []);

    useEffect(() => {
        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            showMetrics();
            listPickingPack();
        }
    }, [searchPeriod, selectedClient, selectedUf]);

    useEffect(() => {
        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            listPickingPack();
        }
    }, [action, statusPedido, searchNfe, orderList, statusGrafico]);

    return (
        <>
            {openModalGraphExpedition && (
                <Modal
                    onClose={actionModalGraphExpedition}
                    size="xs:w-11/12 lg:w-10/12 min-h-40%"
                    show={openModalGraphExpedition}
                    scIn={false}
                    title={
                        <div className="flex items-center">
                            <h5
                                onClick={() => setTabModal("Análise de remessas por dia")}
                                className={`mr-6 cursor-pointer ${tabModal === "Análise de remessas por dia" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                    }`}
                            >
                                Análise de remessas por dia
                            </h5>
                            <h5
                                onClick={() => setTabModal("Análise de remessas por UF")}
                                className={`mr-6 cursor-pointer ${tabModal === "Análise de remessas por UF" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                    }`}
                            >
                                Análise de remessas por UF
                            </h5>
                            <h5
                                onClick={() => setTabModal("Análise de remessas por Cidade")}
                                className={`mr-6 cursor-pointer ${
                                    tabModal === "Análise de remessas por Cidade" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                }`}
                            >
                                Análise de remessas por Cidade
                            </h5>
                            <h5
                                onClick={() => setTabModal("Análise de remessas por Cliente")}
                                className={`mr-6 cursor-pointer ${tabModal === "Análise de remessas por Cliente" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                    }`}
                            >
                                Análise de remessas por Cliente
                            </h5>
                            <h5
                                onClick={() => setTabModal("Análise de remessas por Transportadora")}
                                className={`mr-6 cursor-pointer ${tabModal === "Análise de remessas por Transportadora" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                                    }`}
                            >
                                Análise de remessas por Transportadora
                            </h5>
                        </div>
                    }
                >
                    <div className="w-full">
                        {tabModal === "Análise de remessas por dia" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={isLoadingMetrics}
                                        selectedClient={selectedClient}
                                        container="GraphSlaExpeditionPeriod"
                                        modality="byPeriodDispatched"
                                        additionalGraph={true}
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                        {tabModal === "Análise de remessas por UF" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={isLoadingMetrics}
                                        selectedClient={selectedClient}
                                        container="stateCode"
                                        modality="byStateCode"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                        {tabModal === "Análise de remessas por Cidade" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={isLoadingMetrics}
                                        selectedClient={selectedClient}
                                        container="city"
                                        modality="byCity"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                        {tabModal === "Análise de remessas por Cliente" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={isLoadingMetrics}
                                        selectedClient={selectedClient}
                                        container="byClient"
                                        modality="byClient"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                        {tabModal === "Análise de remessas por Transportadora" && (
                            <>
                                {!dataExpedition.average ? (
                                    <>
                                        <Card>
                                            <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                                <ClipLoader size={20} loading={true} />
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <GraphWarehouseSla
                                        data={dataExpedition}
                                        isLoading={isLoadingMetrics}
                                        selectedClient={selectedClient}
                                        container="byShippingCompany"
                                        modality="byShippingCompany"
                                        openModalGraphExpedition={openModalGraphExpedition}
                                        actionModalGraphExpedition={actionModalGraphExpedition}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </Modal>
            )}
            <Layout>
                <ModalExport
                    modalExport={modalExport}
                    actionModalExport={actionModalExport}
                    isDownloading={isDownloading}
                    downloadList={downloadList}
                    title="Expedição"
                />
                <HeaderArmazem
                    title="Armazém > Expedição"
                    clientsOptions={clientsOptions}
                    setSelectedClient={setSelectedClient}
                    noClientOptionsMessage={noClientOptionsMessage}
                    loadClientOptions={loadClientOptions}
                    isLoadingClients={isLoadingClients}
                    setSelectedUf={setSelectedUf}
                    setMeta={setMeta}
                    handleStatusGrafico={setStatusGrafico}
                    handleStatusPedido={setStatusPedido}
                />
                <div className="flex flex-wrap p-4">
                    <Card className="w-3/12 md-full">
                        {!dataExpedition.average ? (
                            <>
                                <Card>
                                    <TitleC6>Análise de remessas (média)</TitleC6>
                                    <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                        <ClipLoader size={20} loading={true} />
                                    </div>
                                </Card>
                            </>
                        ) : (
                            <GraphWarehouseSla
                                title="Análise de remessas (média)"
                                data={dataExpedition}
                                isLoading={isLoadingMetrics}
                                selectedClient={selectedClient}
                                container="GraphSlaExpeditionTotal"
                                modality="byTotal"
                                openModalGraphExpedition={openModalGraphExpedition}
                                actionModalGraphExpedition={actionModalGraphExpedition}
                            />
                        )}
                    </Card>
                    <Card className="w-9/12 md-full">
                        <ABCAnalysisGraph
                            modality="EXPEDICAO"
                            title="Curva ABC - Expedição"
                            searchPeriod={searchPeriod}
                            selectedClient={selectedClient}
                            selectedUf={selectedUf}
                            handleStatus={setStatusGrafico}
                            statusGrafico={statusGrafico}
                            setMeta={setMeta}
                        />
                        <DeliveryStatusGraph
                            modality="STATUS_COLETA"
                            title="Status de Coleta"
                            searchPeriod={searchPeriod}
                            selectedClient={selectedClient}
                            selectedUf={selectedUf}
                            handleStatus={setStatusPedido}
                            statusGrafico={statusGrafico}
                            statusPedido={statusPedido}
                            setMeta={setMeta}
                        />
                    </Card>
                </div>
                <div className="p-4">
                    <Card>
                        <S.Table>
                            <S.TableHeader>
                                <S.ContainerFluid>
                                    <S.ContainerSection>
                                        <TitleC6>Expedição</TitleC6>
                                        <S.ContainerSearch>
                                            <S.ContainerInput>
                                                <BiSearch color="#00000087" />
                                                <S.Input
                                                    placeholder="Buscar por NFE"
                                                    onChange={event => handleSearchNfe(event.target.value)}
                                                />
                                            </S.ContainerInput>
                                        </S.ContainerSearch>
                                    </S.ContainerSection>
                                    <S.ContainerSection>
                                        <S.ButtonIcon>
                                            <S.ButtonIconText onClick={() => setModalExport(!modalExport)}>
                                                Exportar
                                            </S.ButtonIconText>
                                            <ExportarIcon />
                                        </S.ButtonIcon>
                                    </S.ContainerSection>
                                </S.ContainerFluid>
                            </S.TableHeader>
                            <div>
                                <XpandContainer>
                                    <S.LineHeadTable>
                                        <S.ColumnHeadTable width="130">
                                            <S.LineHeadText className="text-roxo_oficial">
                                                Status
                                            </S.LineHeadText>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="190">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.usuarioConferencia", 3)}
                                                cursor>
                                                Conferente
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.usuarioConferencia") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.usuarioConferencia", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.usuarioConferencia", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.usuarioConferencia", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="130">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.ufArmazem", 3)}
                                                cursor>
                                                Armazém
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.ufArmazem") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.ufArmazem", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.ufArmazem", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.ufArmazem", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="140">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.nuNota", 3)}
                                                cursor>
                                                Nota Fiscal
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.nuNota") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.nuNota", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.nuNota", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.nuNota", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="100">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.serieNf", 3)}
                                                cursor>
                                                Série
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.serieNf") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.serieNf", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.serieNf", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.serieNf", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="140">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.nuNota", 3)}
                                                cursor>
                                                Pedido
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.nuNota") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.nuNota", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.nuNota", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.nuNota", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="175">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.dtFaturamento", 3)}
                                                cursor>
                                                Faturado em
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.dtFaturamento") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.dtFaturamento", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.dtFaturamento", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.dtFaturamento", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="400">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.nomeDepositante", 3)}
                                                cursor>
                                                Depositante
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.nomeDepositante") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeDepositante", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeDepositante", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.nomeDepositante", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="400">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.recipientCity", 3)}
                                                cursor>
                                                Cidade destinatário
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.recipientCity") ? (
                                                    orderList.order == "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.recipientCity", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.recipientCity", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.recipientCity", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="400">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.nomeDestinatario", 3)}
                                                cursor>
                                                Destinatário
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.nomeDestinatario") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeDestinatario", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeDestinatario", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.nomeDestinatario", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="160">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.ufDestinatario", 3)}
                                                cursor>
                                                UF Destinatário
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.ufDestinatario") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.ufDestinatario", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.ufDestinatario", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.ufDestinatario", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="500">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.nomeTransportadora", 3)}
                                                cursor>
                                                Transportadora
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.nomeTransportadora") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeTransportadora", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeTransportadora", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.nomeTransportadora", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="140">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.embLiberado", 3)}
                                                cursor>
                                                Ag. de coleta
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.embLiberado") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.embLiberado", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.embLiberado", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.embLiberado", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="160">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.dtFimColeta", 3)}
                                                cursor>
                                                Coletado em
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.dtFimColeta") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.dtFimColeta", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.dtFimColeta", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.dtFimColeta", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="160" paddingRight="40">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.qtVolumes", 3)}
                                                cursor>
                                                Volumes
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.qtVolumes") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.qtVolumes", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.qtVolumes", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.qtVolumes", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="120">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.cubagemM3", 3)}
                                                cursor>
                                                Cub.
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.cubagemM3") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.cubagemM3", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.cubagemM3", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.cubagemM3", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="160" paddingRight="40">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.pesoBrutoKg", 3)}
                                                cursor>
                                                Peso Bruto
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.pesoBrutoKg") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.pesoBrutoKg", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.pesoBrutoKg", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.pesoBrutoKg", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                    </S.LineHeadTable>
                                    {!load && (
                                        <>
                                            {list &&
                                                list.map((entity, key) => {
                                                    return <LineTableExp entity={entity} />;
                                                })}
                                        </>
                                    )}
                                    {load ? (
                                        <div className="text-center mt-4">
                                            <ClipLoader size={40} loading={load} />
                                        </div>
                                    ) : (
                                        <>{list.length === 0 && <div className="text-center mt-4">Nenhum dado encontrado</div>}</>
                                    )}
                                    {meta.total_pages > 1 && (
                                        <Paginate
                                            meta={meta}
                                            setMeta={setMeta}
                                            action={action}
                                            setAction={setAction}
                                            showDetails={true}
                                        />
                                    )}
                                </XpandContainer>
                            </div>
                        </S.Table>
                    </Card>
                </div>
            </Layout>
        </>
    );
};

export default IndexPage;
